
import Vue, { PropType } from "vue";
import { fromToolstationCdnUrl } from "~/utils/fromToolstationCdn";
import { SocialLinks } from "~/components/layout/footer/footer-middle/social-links/SocialLinks";

export default Vue.extend({
  name: "SocialLinks",
  props: {
    socialLinks: {
      type: Object as PropType<SocialLinks>,
      default: () => ({}),
    },
  },
  methods: {
    getIcon(id: string): string {
      if (
        ![
          "pinterest",
          "facebook",
          "instagram",
          "linkedin",
          "x",
          "youtube",
        ].includes(id)
      )
        return "";
      return fromToolstationCdnUrl(`social-icons/${id}-icon.svg`, this.$config);
    },
  },
});
